import types from './types';

const reducer = (state, action) => {
  switch (action.type) {
    case types.LOADING:
      return { ...state, location: null, filtered: false };
    case types.LOADING_FILTER:
      return { ...state, institutions: null, filtered: false };
    case types.GET_LOCATION:
      return {
        ...state,
        location: action.data,
      };
    case types.FILTER_CENTERS:
      return {
        ...state,
        institutions: action.institutions,
        filtered: action.filtered,
      };
    case types.GET_LOCATION_FAILED:
    case types.FILTER_CENTERS_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;
