export const getFirstCenters = async (client, zipCode) => {
  const getInstitutionsByZip = `
    query ($zipCode: Int!) {
      getInstitutionsByZip(zipCode: $zipCode) {
        id
        lat
        lng
        name
        description
        logoUrl
        address
        tel
      }
    }
  `;

  const { data } = await client
    .query(getInstitutionsByZip, {
      zipCode,
    })
    .toPromise();

  if (data && data.getInstitutionsByZip && data.getInstitutionsByZip.length > 0) {
    return data.getInstitutionsByZip;
  }

  return null;
};
