import React from 'react';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Provider
import { LocationsProvider } from './context';

// Components
import Map from './Map';
import CardList from './CardList';
import Filter from './Filter';

const Locations = () => {
  return (
    <LocationsProvider institutions={[]}>
      <Row>
        <Col xs={12}>
          <Map />
        </Col>
        <Col xs={12}>
          <Filter />
        </Col>
        <Col xs={12}>
          <CardList />
        </Col>
      </Row>
    </LocationsProvider>
  );
};

export default Locations;
