import axios from 'axios';
import types from './types';

//Configuration
import Config from '../../../config';

export const getLocationIp = async dispatch => {
  dispatch({ type: types.LOADING });

  await getLocation(dispatch);
};

const getLocation = async dispatch => {
  navigator.geolocation.getCurrentPosition(
    position => {
      return showPosition(position, dispatch);
    },
    () => {
      dispatch({
        type: types.GET_LOCATION,
        data: { lat: 33.923801, lng: -118.2416, zip: 90059 },
      });
    },
  );
};

const showPosition = async (position, dispatch) => {
  const lat = position.coords.latitude;
  const lng = position.coords.longitude;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${Config.GOOGLE_KEY}`;

  const {
    data: { results },
  } = await axios.get(url);

  let zip = onlyNumbers(results[0].address_components[6].long_name);

  if (zip) {
    dispatch({ type: types.GET_LOCATION, data: { lat, lng, zip } });
  } else {
    dispatch({ type: types.GET_LOCATION_FAILED });
  }
};

const onlyNumbers = txt => {
  let number = txt.match(/\d/g);
  number = number.join('');
  return number;
};

export const filterByCity = async (dispatch, client, city) => {
  dispatch({ type: types.LOADING_FILTER });

  const getInstitutionsByCity = `
    query ($city: String!) {
      getInstitutionsByCity(city: $city) {
        id
        lat
        lng
        name
        description
        logoUrl
        address
        tel
      }
    }
  `;

  const { data } = await client
    .query(getInstitutionsByCity, {
      city,
    })
    .toPromise();

  if (data.getInstitutionsByCity) {
    dispatch({
      type: types.FILTER_CENTERS,
      institutions: data.getInstitutionsByCity,
      filtered: true,
    });
  } else {
    dispatch({ type: types.FILTER_CENTERS_FAILED });
  }
};

export const filterByState = async (dispatch, client, state) => {
  dispatch({ type: types.LOADING_FILTER });

  const getInstitutionsByState = `
    query ($state: String!) {
      getInstitutionsByState(state: $state) {
        id
        lat
        lng
        name
        description
        logoUrl
        address
        tel
      }
    }
  `;

  const { data } = await client
    .query(getInstitutionsByState, {
      state,
    })
    .toPromise();

  if (data.getInstitutionsByState) {
    dispatch({
      type: types.FILTER_CENTERS,
      institutions: data.getInstitutionsByState,
      filtered: true,
    });
  } else {
    dispatch({ type: types.FILTER_CENTERS_FAILED });
  }
};

export const filterByZipCode = async (dispatch, client, zipCode) => {
  dispatch({ type: types.LOADING_FILTER });

  const getInstitutionsByZip = `
    query ($zipCode: Int!) {
      getInstitutionsByZip(zipCode: $zipCode) {
        id
        lat
        lng
        name
        description
        logoUrl
        address
        tel
      }
    }
  `;

  const { data } = await client
    .query(getInstitutionsByZip, {
      zipCode,
    })
    .toPromise();

  if (data.getInstitutionsByZip) {
    dispatch({
      type: types.FILTER_CENTERS,
      institutions: data.getInstitutionsByZip,
      filtered: true,
    });
  } else {
    dispatch({ type: types.FILTER_CENTERS_FAILED });
  }
};

export const updateInstitutions = async (dispatch, institutions) => {
  if (institutions) {
    dispatch({ type: types.FILTER_CENTERS, institutions });
  } else {
    dispatch({ type: types.FILTER_CENTERS_FAILED });
  }
};

export default {
  getLocationIp,
  filterByCity,
  filterByState,
  filterByZipCode,
  updateInstitutions,
};
