import React, { useContext } from 'react';
import classNames from 'classnames';
import { scroller } from 'react-scroll';

// Context
import { LocationsContext } from '../../context';

const Card = ({ address, id, name }) => {
  const { cardSelected, selectCard } = useContext(LocationsContext);

  const classes = classNames('card', {
    'card-selected': cardSelected === id,
  });

  const toggleOpenInfo = cardId => {
    selectCard(cardId);
    scrollTo();
  };

  const scrollTo = () => {
    scroller.scrollTo('root', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  return (
    <div id={id} className={classes} onClick={() => toggleOpenInfo(id)}>
      <p className="data__title">{name}</p>
      <p className="data__address">{address}</p>
    </div>
  );
};

export default Card;
