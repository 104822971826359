import React, { useContext, useEffect, useState } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Context } from 'urql';

// Map Options
import mapOptions from './options';

// Context
import { LocationsContext } from '../context';

// Actions
import { getLocationIp, updateInstitutions } from '../context/actions';

// Components
import Loader from '../../Loader';
import { getFirstCenters } from './getFirstCenters';

// Image
import MapMarkers from './markers';

const Map = () => {
  const {
    cardSelected,
    dispatch,
    state: { institutions, filtered, location },
  } = useContext(LocationsContext);
  const [firstCentersLoaded, setFirstCentersLoaded] = useState(false);
  const client = useContext(Context);

  useEffect(() => {
    getLocationIp(dispatch);
  }, [dispatch]);

  const zoom = 12;

  const centerMap = () => {
    if (location && !cardSelected) {
      const centerLat =
        filtered && institutions && institutions.length ? institutions[0].lat : location.lat;
      const centerLng =
        filtered && institutions && institutions.length ? institutions[0].lng : location.lng;

      return {
        lat: centerLat,
        lng: centerLng,
      };
    }

    // if (cardSelected) {
    //   const centerClicked = institutions.filter(institution => institution.id === cardSelected);
    //
    //   return {
    //     lat: centerClicked[0].lat,
    //     lng: centerClicked[0].lng,
    //   };
    // }
  };

  const onLoadMap = async () => {
    if (!firstCentersLoaded) {
      const firstCenters = await getFirstCenters(client, location.zip);

      setFirstCentersLoaded(true);

      updateInstitutions(dispatch, firstCenters);
    }
  };

  return (
    <div className="map-container">
      {(!institutions || !location) && (
        <div className="loader-container">
          <Loader message="loading map..." />
        </div>
      )}

      {institutions && location && (
        <LoadScript googleMapsApiKey="AIzaSyCU7BiX9Ya28LgR5BeOOh072lHkg__lwFI" onLoad={onLoadMap}>
          <GoogleMap
            mapContainerClassName="map-container__map"
            center={centerMap()}
            zoom={zoom}
            options={mapOptions}
          >
            {institutions &&
              institutions.map(institution => {
                const position = {
                  lat: institution.lat,
                  lng: institution.lng,
                };

                return (
                  <MapMarkers
                    key={institution.id}
                    institutionId={institution.id}
                    position={position}
                  />
                );
              })}
          </GoogleMap>
        </LoadScript>
      )}
    </div>
  );
};

export default Map;
