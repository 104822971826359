import React, { useContext } from 'react';

// Context
import { LocationsContext } from '../context';

// Components
import Card from './Card';
import Loader from '../../Loader';

const CardList = () => {
  const {
    state: { institutions, filtered },
  } = useContext(LocationsContext);

  return (
    <div className="centers-list">
      {!institutions && <Loader message="loading centers..." />}

      <div className="centers-message">
        {institutions && institutions.length > 0 && <p>{institutions.length} results found</p>}

        {!filtered && institutions && !institutions.length && (
          <p>0 results found on your location</p>
        )}

        {filtered && institutions && !institutions.length && <p>0 results found</p>}
      </div>

      <div className="card-list">
        {institutions && institutions.map(card => <Card key={card.id} {...card} />)}
      </div>
    </div>
  );
};

export default CardList;
