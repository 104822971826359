import React, { createContext, useMemo, useReducer, useState } from 'react';

import reducer from './reducer';

const LocationsContext = createContext();
const { Provider } = LocationsContext;

const LocationsProvider = ({ children, institutions }) => {
  const [state, dispatch] = useReducer(reducer, { institutions });
  const [cardSelected, setCardSelected] = useState(null);
  const [cityFilter, setCityFilter] = useState(null);
  const [stateFilter, setStateFilter] = useState(null);
  const [zipCodeFilter, setZipCodeFilter] = useState(null);

  const contextValue = useMemo(
    () => ({
      cardSelected,
      dispatch,
      selectCard: cardId => setCardSelected(cardId),
      state,
      cityFilter,
      setCityFilter,
      stateFilter,
      setStateFilter,
      zipCodeFilter,
      setZipCodeFilter,
    }),
    [
      cardSelected,
      dispatch,
      state,
      cityFilter,
      setCityFilter,
      stateFilter,
      setStateFilter,
      zipCodeFilter,
      setZipCodeFilter,
    ],
  );

  return <Provider value={contextValue}>{children}</Provider>;
};

export { LocationsProvider, LocationsContext };
