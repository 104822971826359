import React from 'react';
import ReactDOM from 'react-dom';
import { createClient, Provider } from 'urql';

// Styles
import './index.scss';

// Components
import AppRouter from './components/App';
import * as serviceWorker from './serviceWorker';

const client = createClient({
  url: 'https://api.gotthetest.org/graphql',
});

ReactDOM.render(
  <React.StrictMode>
    <Provider value={client}>
      <AppRouter />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
