import React, { Fragment, useContext } from 'react';

// Bootstrap
import FormControl from 'react-bootstrap/FormControl';

// Context
import { LocationsContext } from '../../context';

const StateFilter = () => {
  const { setStateFilter } = useContext(LocationsContext);

  return (
    <Fragment>
      <FormControl
        className="filters__input state__input"
        id="state-input"
        placeholder="State"
        onChange={e => setStateFilter(e.target.value)}
      />
    </Fragment>
  );
};

export default StateFilter;
