import React, { useContext } from 'react';
import { Context } from 'urql';

// Components
import CityFilter from './City';
import StateFilter from './State';
import ZipCodeFilter from './ZipCode';

// Actions
import { filterByCity, filterByState, filterByZipCode } from '../context/actions';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

// Context
import { LocationsContext } from '../context';

const Filter = () => {
  const { dispatch, cityFilter, stateFilter, zipCodeFilter } = useContext(LocationsContext);
  const client = useContext(Context);

  const filterContent = async event => {
    event.preventDefault();

    if (cityFilter) {
      await filterByCity(dispatch, client, cityFilter);
    } else if (stateFilter) {
      await filterByState(dispatch, client, stateFilter);
    } else if (zipCodeFilter) {
      await filterByZipCode(dispatch, client, zipCodeFilter);
    }
  };

  return (
    <Row>
      <Col className="filter-content__data">
        <Form className="filter-form" onSubmit={event => filterContent(event)}>
          <div className="filter-form__title">
            <h4>Find test centers</h4>
          </div>

          <Row className="filter-form__content">
            <Col xs={12} sm={12} md={3}>
              <CityFilter />
            </Col>

            <Col xs={12} sm={12} md={3}>
              <StateFilter />
            </Col>

            <Col xs={12} sm={12} md={3}>
              <ZipCodeFilter />
            </Col>

            <Col xs={12} sm={12} md={3}>
              <Button onClick={event => filterContent(event)} className="filter__button">
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default Filter;
