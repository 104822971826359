import React from 'react';

// Styles
import './styles.scss';

const NotFound = () => (
  <div>
    <p>404 Page Not Found</p>
  </div>
);

export default NotFound;
