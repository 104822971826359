import React from 'react';

// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

const Loader = ({ message }) => (
  <Row>
    <Col className="loader">
      <Spinner animation="border" role="status" />
      {message && <span>{message}</span>}
    </Col>
  </Row>
);

export default Loader;
