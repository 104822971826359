import React, { useContext } from 'react';
import { Marker } from '@react-google-maps/api';
import { scroller } from 'react-scroll';

// Icon
import MarkerIcon from '../../../images/marker_icon.png';
import SelectedMarkerIcon from '../../../images/selected_marker_icon.png';
import { LocationsContext } from '../context';

const MapMarkers = ({ institutionId, position }) => {
  const { cardSelected, selectCard } = useContext(LocationsContext);

  const toggleOpenInfo = cardId => {
    selectCard(cardId);
    scrollTo(cardId);
  };

  const scrollTo = id => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  };

  const setMarkerIcon = id => {
    if (cardSelected === id) {
      return SelectedMarkerIcon;
    }

    return MarkerIcon;
  };

  return (
    <Marker
      icon={setMarkerIcon(institutionId)}
      position={position}
      onClick={() => toggleOpenInfo(institutionId)}
    />
  );
};

export default MapMarkers;
