import React, { Fragment, useContext } from 'react';

// Bootstrap
import FormControl from 'react-bootstrap/FormControl';

// Context
import { LocationsContext } from '../../context';

const ZipCodeFilter = () => {
  const { setZipCodeFilter } = useContext(LocationsContext);

  return (
    <Fragment>
      <FormControl
        className="filters__input zipcode__input"
        id="zipcode-input"
        placeholder="Zip code"
        onChange={e => setZipCodeFilter(e.target.value)}
      />
    </Fragment>
  );
};

export default ZipCodeFilter;
