import React from 'react';

// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Components
import Locations from '../Locations';

const Home = () => (
  <Container fluid>
    <Row>
      <Col>
        <div className="home">
          <h2 className="home__title">Testing Map</h2>
          <h6 className="home__subtitle">Choose your state to see the testing centers</h6>
        </div>
      </Col>
    </Row>

    <Locations />
  </Container>
);

export default Home;
